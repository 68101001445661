/* .App {
    text-align: center !important;
} */

html {
    margin: 0 !important;
    padding: 0 !important;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'SourceSansPro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    background: #CFDBD5 !important ;/*!important;*/
}

.main-wrap p {
    color: #333533 !important;
    font-weight: 400 !important;
    font-size: 1.3rem !important;
    max-width: 80% !important;
    font-family: 'SourceSansPro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue" !important;
    opacity: 0.9 !important;
}

.container {
    min-height: 100vh !important;
    width: 100% !important;
	/* background: url('./liquid-cheese.svg') !important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.header {
    background-color: #F5CB5C !important;
    min-height: 10vh !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: calc(10px + 2vmin) !important;
    color: #10375c !important;
}

.main-wrap h1 {
    color: rgb(48, 48, 48) !important;
    font-family: 'Playfair Display' !important;
    font-size: 5.5rem !important;
    font-weight: 900 !important;
}

.main-wrap p {
    color: #333533 !important;
    font-weight: 400 !important;
    font-size: 1.3rem !important;
    max-width: 80% !important;
    font-family: 'SourceSansPro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue" !important;
    opacity: 0.9 !important;
}

.card {
    width: 320px !important;
    height: auto !important;
    color: #242423 !important;
    position: relative !important;
    background: #CFDBD5 !important ;/*!important;*/
    border: 0px !important;
}

.card img {
    width: 100% !important;
    height: 400px !important;
}

.stock-container {
    padding-left: 3em !important;
    padding-right: 3em !important;
    margin-top: 3em !important;
}

.cardszippa {
    max-width:  768px !important ;/*!important;*/
    height: 100% !important;
    display: grid !important;
    grid-template-columns: 768px !important ;/*!important;*/
    grid-template-rows: auto !important;
    margin: 20px auto !important;
    padding-bottom: 3% !important;
    align-items: baseline !important;
    justify-content: center !important;
    background: #CFDBD5 !important;
    grid-row-gap: 50px !important;
}

.cards {
    max-width: 360px !important;
    height: 100% !important;
    display: grid !important;
    grid-template-columns: 320px !important;
    grid-template-rows: auto !important;
    margin: 20px auto !important;
    padding-bottom: 3% !important;
    align-items: baseline !important;
    justify-content: center !important;
    background: #CFDBD5 !important;
    grid-row-gap: 50px !important;
}


.cards-title {
    max-width: 320px ;
    margin: 40px auto ;
    font-size: 3rem ;
    color: #242423 ;
}

.main-slide{
	
	text-align: center !important;
	padding: 3% 0px !important;
	width:100% !important;
	flex:4 !important;
	display:flex !important;
	flex-direction: column !important;
	align-items: center !important;	
}

.main-slide h1{
    font-size:9rem !important;

}

.main-slide p{
	text-align: center !important;
    font-size: 2.5rem !important;
}
.fa{
    color:#242423 !important;
}

.fa-2x{
	color:#242423 !important;
}
.top-bar{
	position: absolute !important;
	top:20px !important;
	right:30px !important;
	color:#333533 !important;
	z-index:9999 !important;
	cursor: pointer !important;

	
}

.top-bar-search{
	position: absolute !important;
	top:20px !important;
	right:130px !important;
	color:#333533 !important;
	z-index:9999 !important;
	cursor: pointer !important;
}


.top-bar-back{
position: absolute !important;
top:20px !important;
right:80px !important;
color:#333533 !important;
z-index:9999 !important;
cursor: pointer !important;


}

.top-bar-back-home{
    position: absolute !important;
    top: 19px !important;
    right: 180px !important;
    color: #333533 !important;
    z-index: 9999 !important;
    cursor: pointer !important;
    font-size: 2.3rem;
}




.fa-spinner{
	position: fixed !important;
	top:45vh !important;
	left:0 !important;
	transform: translateX(49%) !important;
}

.fa-search:before {
    content: "\f002" !important;
    color:#242423 !important;
}

.fa-refresh:before {
    content: "\f021" !important;
    color:#242423 !important;
}

.top-bar-email {
    position: absolute !important;
    top: 20px !important;
    right: 80px !important;
    color: #333533 !important;
    z-index: 9999 !important;
    cursor: pointer !important;
}

.search{
	height: 100% !important;
	padding-top:20% !important;
	display:flex !important;
	margin:0 auto !important;
	flex-direction: column !important;
	align-items: center !important;
	width:100% !important;
	
}

.category-menu{
	width:100% !important;
	flex:1 !important;	
	padding:5% !important;
	box-sizing: border-box !important;
}
.category-menu ul{
    line-height:2 !important;
	list-style-type: none !important;
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	flex-wrap: wrap !important;
	text-align: center !important;
	padding: 0 !important;
}
.category-menu button{
    font-size: 16px !important;
    font-weight: 200 !important;
    letter-spacing: 1px !important;
    padding: 13px 20px 13px !important;
    outline: 0 !important;
    border: 1px solid black !important;
    cursor: pointer !important;
    background-color: rgba(0, 0, 0, 0) !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    touch-action: manipulation !important;


	padding:5px 10px !important;
	/* border-radius:10px !important; */
    margin: 5px !important;
}
.search-list{
	display:flex !important;
	flex-direction: column !important;
	margin:20px auto !important;
	width:100% !important;
	align-items: center !important;
}

.item {
	display:grid !important;
	grid-template-columns: 3fr 2fr !important;
	grid-template-rows: auto !important;
	padding:20px 4% !important;
	width:40% !important;
	margin:5px 0 !important;
	grid-column-gap: 5% !important;
	background: #E8EDDF !important;
	color:#242423 !important;
	box-sizing: border-box !important;
	
}
.item-author{
	font-size:0.9rem !important;	
}
.item-title{
  font-size:1.05rem !important;
}
.item:hover{
	background:#F5CB5C !important;
}

.input{
	width:320px !important;
	height: 50px !important;
	border:0 !important;
	background:#E8EDDF !important;
	font-size: 1.2em !important;
	color:#242423 !important;
	padding-left: 3% !important;
	box-sizing: border-box !important;
	font-weight: 300 !important;


}
a:link {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
.btn{
	background:#CFDBD5 ;
    margin:20 auto ;
    padding:10px 20px ;
	text-align: center ;
	width: 120px ;
    border: 1px solid black ;

    font-size:1.2rem ;
	font-weight: 400 ;
	cursor: pointer ;	
}

.btndiv{
    padding: 10px 0 !important;
    display: flex !important;
    justify-content: flex-end !important;
    margin: 1% 7% !important;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: black !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

#inverted .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%3174ee' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")!important;
  }
#inverted .carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%3174ee' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}


#jobtitle .carousel-control-next-icon {
    visibility:hidden !important;
}

#jobtitle .carousel-control-prev-icon   {
    visibility:hidden !important;
}


@media only screen and (max-device-width: 414px){

    .cardszippa {
        max-width:  450px !important ;/*!important;*/
        height: 100% !important;
        display: grid !important;
        grid-template-columns: 450px !important ;/*!important;*/
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        padding-bottom: 3% !important;
        align-items: baseline !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
        grid-row-gap: 50px !important;
    }
    .main-slide p{
		font-size:1.5rem !important;
		max-width: 80% !important;
	
	}

    .main-slide h1{
		font-size:5rem !important;
        padding-top: 30px;
	}
	.main-slide p{
		font-size:1.2rem !important;
		max-width: 60% !important;
        padding-top: 30px;    
	
	}

}



@media(min-width:483px) {

    .cardszippa {
        max-width:  450px !important ;/*!important;*/
        height: 100% !important;
        display: grid !important;
        grid-template-columns: 450px !important ;/*!important;*/
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        padding-bottom: 3% !important;
        align-items: baseline !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
        grid-row-gap: 50px !important;
    }
    .main-slide p{
		font-size:1.5rem !important;
		max-width: 80% !important;
	
	}

    .main-slide h1{
		font-size:5rem !important;
	}
	.main-slide p{
		font-size:1.8rem !important;
		max-width: 60% !important;
	
	}

}


@media(min-width:660px) {
    .container {
        min-height: 100% !important;
        width: 100% !important;
    }
    .cards {
        max-width: 660px !important;
        display: grid !important;
        height: auto !important;
        grid-template-columns: 320px 320px !important;
        grid-column-gap: 10px !important;
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
    }
    .cardszippa {
        max-width:  660px !important ;/*!important;*/
        height: 100% !important;
        display: grid !important;
        grid-template-columns: 660px !important ;/*!important;*/
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        padding-bottom: 3% !important;
        align-items: baseline !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
        grid-row-gap: 50px !important;
    }
    
    .cards-title {
        max-width: 680px ;
        padding-left: 20px ;
        margin: 40px auto;
    }

    .search{
		height: 100% !important;
		padding-top:10% !important;
		display:flex !important;
		margin:0 auto !important;
		flex-direction: column !important;
		align-items: center !important;
		width:100% !important;
	}

    .main-slide h1{
		font-size:5rem !important;
	}
	.main-slide p{
		font-size:1.8rem !important;
		max-width: 60% !important;
	
	}

}

@media(min-width:980px) {
    .cards {
        max-width: 960px !important;
        display: grid !important;
        height: auto !important;
        grid-template-columns: 320px 320px 320px !important;
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
    }
    .cardszippa {
        max-width:  768px !important ;/*!important;*/
        height: 100% !important;
        display: grid !important;
        grid-template-columns: 768px !important ;/*!important;*/
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        padding-bottom: 3% !important;
        align-items: baseline !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
        grid-row-gap: 50px !important;
    }
    .cards-title {
        max-width: 1000px ;
        margin: 40px auto;
    }
    .main-slide h1{
		font-size:6rem !important;
	}
	.main-slide p{
		font-size:1.8rem !important;
		max-width: 60% !important;
	
	}
}

@media(min-width:1340px) {
    .cards {
        max-width: 1290px !important;
        display: grid !important;
        height: auto !important;
        grid-template-columns: 320px 320px 320px 320px !important;
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
    }
    .cardszippa {
        max-width:  768px !important ;/*!important;*/
        height: 100% !important;
        display: grid !important;
        grid-template-columns: 768px !important ;/*!important;*/
        grid-template-rows: auto !important;
        margin: 20px auto !important;
        padding-bottom: 3% !important;
        align-items: baseline !important;
        justify-content: center !important;
        background: #CFDBD5 !important;
        grid-row-gap: 50px !important;
    }
    .cards-title {
        max-width: 1340px ;
        margin: 40px auto;
    }
    .main-slide h1{
		font-size:8rem !important;

	}
	.main-slide p{
		font-size:2.2rem !important;
		max-width: 60% !important;
		
	
	}
}